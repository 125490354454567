.MuiPhoneNumber-flagButton {
    width: min-content;
}
.business-menu{
    z-index: 1 !important;
}

.css-12ba56d{
    padding-top: 0;
    padding-bottom: 0;
}

.top-nav-item{
    padding-top: 35px;
    padding-bottom: 32px;
}
.css-10ivwj6-MuiTypography-root{
    white-space: normal;
}
.css-1kfk2y6-MuiStack-root {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	height: 100px;
	min-width: max-content;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-12.MuiGrid-grid-lg-6.css-18341x1-MuiGrid-root{
	flex-basis: 0 !important;
}
.css-185gc9c {
	width: 100%;
	max-width: 100%;
}
.MuiGrid-root.MuiGrid-item.css-13i4rnv-MuiGrid-root{
	flex: 0.5;
}